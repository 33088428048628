import React from "react"
import { Row, Col, Container, Carousel } from "react-bootstrap"
import Layout from "../components/layout"
import Seo from "../components/seo"

import { StaticImage } from "gatsby-plugin-image"

// import typicalHome from "../images/typical-home.jpg"
// import oneStep from "../images/1-STEP.png"
// import fifteenSteps from "../images/15-steps.png"
// import traditionalVsAB from "../images/traditional-vs-ab.jpg"
// import healthier from "../images/healthier.jpg"

import { Link } from 'gatsby'
// import Img from 'gatsby-image'
// import indexImage from "../images/spray-bg.jpg"
import multiFamilyPDF from "../images/air-sealing-multi-family.pdf"

// import usgbc from "../images/USGBCC.png"
// import homeInnovation from "../images/NGBS-Home-InnovationC.png"
// import greenGuard from "../images/Greenguard-GoldC.png"
// import ibs from "../images/IBS-Most-Innovative.png"

// import sealingSize from "../images/Sealing-Sizes-01.png"
// import awardsWhite from "../images/aero-barrier-awards-white.png"


// import icon01 from "../images/icons/01-single-family.png"
// import icon02 from "../images/icons/02-multi-family.png"
// import icon03 from "../images/icons/03-schools.png"
// import icon04 from "../images/icons/04-hospitals.png"
// import icon05 from "../images/icons/05-offices.png"
// import icon06 from "../images/icons/06-commerical.png"

// Gallery

// import carousel01 from "../images/index-carousel/01-key.jpeg"
// import carousel02 from "../images/index-carousel/02-lighting-outet.jpg"
// import carousel03 from "../images/index-carousel/03-electrical.jpg"
// import carousel04 from "../images/index-carousel/04-light-fixture.jpg"
// import carousel05 from "../images/index-carousel/05-punchouts.jpg"

// import featured01 from "../images/new-icons/edisongold_award_logo_resized.jpg"
// import featured02 from "../images/new-icons/eqawards-logo.png"
// import featured03 from "../images/new-icons/FastCompany_WorldChangingIdeas_logo.png"
// import featured04 from "../images/new-icons/GB-Sustainabilty Awards 2019.jpg"
// import featured05 from "../images/new-icons/IBS-Best-In-Show.png"
// import featured06 from "../images/new-icons/IBS-Most-Innovative.png"

// import awards01 from "../images/awards/DOE_Logo.gif"
// import awards02 from "../images/awards/gba_logo_resized.png"
// import awards03 from "../images/awards/JLC square.png"
// import awards04 from "../images/awards/MakeItRight_square.png"
// import awards05 from "../images/awards/ThisOldHouse_square.png"
// import awards06 from "../images/awards/zero_energy_project_logo_square.jpg"

// import doubleSprayImg from "../images/Double-Spray-Nozzle-dark.jpg"

import LazyIframe from "./vimeo-test"

const indexPage = props => (
  <Layout>
        {/* <Seo title="100% Air Sealing Guaranteed"/> */}
        
        <section className="page-section">
        <div style={{ display: "grid" }}>
      {/* You can use a GatsbyImage component if the image is dynamic */}
      <StaticImage
        style={{
          gridArea: "1/1",
          // You can set a maximum height for the image, if you wish.
          // maxHeight: 600,
        }}
        className="masthead"
        placeholder="blurred"
        layout="fullWidth"
        // You can optionally force an aspect ratio for the generated image
        // aspectRatio={3 / 1}
        // This is a presentational image, so the alt should be an empty string
        alt=""
        // Assisi, Perúgia, Itália by Bernardo Ferrari, via Unsplash
        src={"../images/spray-bg.jpg"}
        quality={60}
        formats={["auto", "webp", "avif"]}
      />
      <div
        style={{
          // By using the same grid area for both, they are stacked on top of each other
          gridArea: "1/1",
          position: "relative",
          // This centers the other elements inside the hero component
          placeItems: "center",
          display: "grid",
        }}
        className="masthead"

      >
        {/* Any content here will be centered in the component */}
        <div className="text-center">
        <h1>SEAL IT TIGHT. BUILD IT RIGHT.</h1>
        <h2>Seal your entire building, all-at-once. Guaranteed results.</h2>
        </div>
      </div>
    </div>

        {/* <div className="masthead fixed" style={{ backgroundImage: `url(${indexImage})` }}>
          <Container className="container h-100">
            <Row className="row h-100 align-items-center">
              <Col className="text-center">
                <h1>SEAL IT TIGHT. BUILD IT RIGHT.</h1>
                <h2>Seal your entire building, all-at-once. Guaranteed results.</h2>
              </Col>
            </Row>
          </Container>
        </div> */}
          <Container className="mt-4">
            <h2 className="text-center">AeroBarrier seals gaps & invisible air leaks. <br />
            An airtight structure provides you
healthier air quality, greater
thermal comfort, and energy
savings you’ll enjoy.</h2>
            <Row className="pt-2">
              <Col lg={2} xs={6} className="text-center">
                <StaticImage
                  src="../images/icons/01-single-family.png"
                  width={100}
                  placeholder="blurred"
                  quality={50}
                  formats={["auto", "webp", "avif"]}
                  alt="Single Family Logo"
                />
                <p><strong>Residential</strong></p>
              </Col>
              <Col lg={2} xs={6} className="text-center">
                <StaticImage
                  src="../images/icons/02-multi-family.png"
                  width={100}
                  placeholder="blurred"
                  quality={50}
                  formats={["auto", "webp", "avif"]}
                  alt="Multi Family Logo"
                />
                <p><strong>Multi-Family</strong></p>
              </Col>
              <Col lg={2} xs={6} className="text-center">
                <StaticImage
                  src="../images/icons/03-schools.png"
                  width={100}
                  placeholder="blurred"
                  quality={50}
                  formats={["auto", "webp", "avif"]}
                  alt="Schools Logo"
                />
                <p><strong>Schools</strong></p>
              </Col>
              <Col lg={2} xs={6} className="text-center">
                <StaticImage
                  src="../images/icons/04-hospitals.png"
                  width={100}
                  placeholder="blurred"
                  quality={50}
                  formats={["auto", "webp", "avif"]}
                  alt="Hospitals Logo"
                />
                <p><strong>Hospitals</strong></p>
              </Col>
              <Col lg={2} xs={6} className="text-center">
                <StaticImage
                  src="../images/icons/05-offices.png"
                  width={100}
                  placeholder="blurred"
                  quality={50}
                  formats={["auto", "webp", "avif"]}
                  alt="Offices Logo"
                />
                <p><strong>Offices</strong></p>
              </Col>
              <Col lg={2} xs={6} className="text-center">
                <StaticImage
                  src="../images/icons/06-commerical.png"
                  width={100}
                  placeholder="blurred"
                  quality={50}
                  formats={["auto", "webp", "avif"]}
                  alt="Commerical Building Logo"
                />
                <p><strong>Commerical</strong></p>
              </Col>
            </Row>

            <Container className="my-3">
            <p className="text-lg-center">Besides home air sealing, air sealing for buildings of all types is a specialty of Western AeroBarrier.</p>
          </Container>
            
            <Row>
              <Col lg={2}></Col>
              <Col lg={8} className="tealBox">
                <h2>How It Works</h2>
                <LazyIframe />
                {/* <div className="video-responsive">
                  <iframe title="Video" src="https://player.vimeo.com/video/510004815?color=068ebe&title=0&byline=0&portrait=0" width="100%" frameborder="0"  allowfullscreen ></iframe>
                </div> */}
                <StaticImage
                  src="../images/aero-barrier-awards-white.png"
                  placeholder="blurred"
                  quality={50}
                  formats={["auto", "webp", "avif"]}
                  alt="Awards"
                />
              </Col>
              <Col lg={2}></Col>
            </Row>
          </Container>
        
        <div className="light-gray-box dark-blue-graident">
          <h2 className="text-center pt-2 ">Air Sealing Solved</h2>
        </div>
        <Container className="pt-3">
          
          <Row>
            <Col lg={6} className="text-center">
              <StaticImage 
                width={300}
                src="../images/1-STEP.png" 
                alt="1 Step"
                className="img-fluid"
                placeholder="blurred"
                quality={50}
                formats={["auto", "webp", "avif"]}
              />
            </Col>
            <Col lg={6} className="pt-2">
              <h3>Simple, One Step Air Sealing</h3>
              <p>Consolidate your air sealing with AeroBarrier, and meet code requirements simply, easily, and consistently. We guarantee that you will pass code.</p>
            </Col>
          </Row>
          <p className="text-center font-size-lg">OR</p>
          <Row>
            <Col lg={6} className="text-center">
              <StaticImage 
                width={300}
                src="../images/15-steps.png" 
                alt="15 Steps"
                className="img-fluid"
                placeholder="blurred"
                quality={50}
                formats={["auto", "webp", "avif"]}
              />
            </Col>
            <Col lg={6} className="pt-2">
              <h3>The Traditional Approach</h3>
              <p>Traditional air sealing approaches are a hodge podge effort from multiple trades that requires all sorts of babysitting. Results are never certain, and there’s nothing worse than failing a blower door test just before occupancy. </p>
            </Col>
          </Row>
          <p className="text-center"><a href={multiFamilyPDF} target="_blank" rel="noreferrer">Download the PDF that compares the different sealing approaches</a></p>
        </Container>

        
        <Container>
          <Row>
            <Col lg={6}>
              <div className="blueBox">
                <h2>Save Time</h2>
                <ul>
                  <li>Typically in and out within 3 hours</li>
                  <li>Meet code requirements the first time</li>
                  <li>Airtight in hours instead of days/weeks</li>
                </ul>
                <StaticImage 
                  width={700}
                  src="../images/index-image.jpg" 
                  alt="Person sweeping"
                  className="img-fluid"
                  placeholder="blurred"
                  quality={50}
                  formats={["auto", "webp", "avif"]}
                />
                {/* <Img fluid={props.data.indexImage.childImageSharp.fluid}    alt="Person Sweeping" /> */}
                <h3>Resume Work the Same Day</h3>
                <p>Resume all work 30 minutes after the sealant process is complete.</p>
              </div>
            </Col>
            <Col lg={6}>
              <div className="tealBox">
                <h2>Save Money</h2>
                <ul>
                  <li>Tight buildings require smaller HVAC systems</li>
                  <li>Stop overspending on utilities</li>
                  <li>Western AeroBarrier house air sealing cost is surprisingly affordable, and the energy savings significant.</li>

                </ul>
                {/* <Img fluid={props.data.doubleSpray.childImageSharp.fluid} className="img-fluid"   alt="Double Spray" /> */}
                {/*  */}
                <StaticImage src="../images/Double-Spray-Nozzle-dark.jpg" alt="Double Spray" />
                <h3>Fix Workmanship Error</h3>
                <p>Aerobarrier fills gaps as small as a human hair and up to 1/2 inch wide. All sorts of gaps left by tradesmen get filled with AeroBarrier–things like drywall or sheathing overcuts, gaps between boards, and unsealed penetrations.</p>
              </div>
            </Col>
          </Row>
        </Container>
        <Container className="my-5">
        
        <h3 className="text-center my-3">Western AeroBarrier reviews and awards are impressive</h3>

        <Row>
            <Col xs={6} sm={3} lg={2} className="px-3">
              <StaticImage
                src="../images/new-icons/edisongold_award_logo_resized.jpg"
                width={300}
                placeholder="blurred"
                quality={50}
                formats={["auto", "webp", "avif"]}
                alt="Edison Gold Award"
              />
            </Col>
            <Col xs={6} sm={3} lg={2} className="px-3">
              <StaticImage
                src="../images/new-icons/eqawards-logo.png"
                width={300}
                placeholder="blurred"
                quality={50}
                formats={["auto", "webp", "avif"]}
                alt="EQ Award Logo"
              />
            </Col>
            <Col xs={6} sm={3} lg={2} className="px-3">
              <StaticImage
                src="../images/new-icons/FastCompany_WorldChangingIdeas_logo.png"
                width={300}
                placeholder="blurred"
                quality={50}
                formats={["auto", "webp", "avif"]}
                alt="World Changing Ideas Logo"
              />            
            </Col>
            <Col xs={6} sm={3} lg={2} className="px-3">
              <StaticImage
                src="../images/new-icons/gb-sustainabilty-awards-2019.jpg"
                width={300}
                placeholder="blurred"
                quality={50}
                formats={["auto", "webp", "avif"]}
                alt="World Changing Ideas Logo"
              />             
            </Col>
            <Col xs={6} sm={3} lg={2} className="px-3">
            <StaticImage
                src="../images/new-icons/IBS-Best-In-Show.png"
                width={300}
                placeholder="blurred"
                quality={50}
                formats={["auto", "webp", "avif"]}
                alt="IBS Best In Show 2018 Award Logo"
              />
            </Col>
            <Col xs={6} sm={3} lg={2} className="px-3">
              <StaticImage
                src="../images/new-icons/IBS-Most-Innovative.png"
                width={300}
                placeholder="blurred"
                quality={50}
                formats={["auto", "webp", "avif"]}
                alt="IBS Most Innovative Building Project 2018 Award Logo"
              />            
            </Col>
          </Row>

          

        </Container>
        <div className="gold-bar">
          <Container className="text-center">
            <p>How much does AeroBarrier cost?</p>
            <h3>CONTACT US FOR A FREE QUOTE</h3>
          <Link to="/contact/" class="btn btn-light text-center text-uppercase"><strong>CONTACT</strong></Link>
            
          </Container>
        </div>

          <Container className="py-3">
            <Row>
            <Col lg={6}>
              <h2>Seal Every Nook & Cranny</h2>
              <p>AeroBarrier makes it easy to seal gaps as tiny as a human hair or as large as 1/2 an inch. Other air sealing companies and contractors don’t have the technology that Western AeroBarrier uses to completely protect against air leaks.</p>
              <p>
                <StaticImage
                  src="../images/Sealing-Sizes-01.png"
                  width={750}
                  placeholder="blurred"
                  quality={50}
                  formats={["auto", "webp", "avif"]}
                  alt="Sealing Size Guage"
                />
              </p>
            </Col>
            <Col lg={6}>
              <Carousel >
                <Carousel.Item>
                  <StaticImage
                    src="../images/index-carousel/01-key.jpg"
                    width={750}
                    placeholder="blurred"
                    quality={50}
                    formats={["auto", "webp", "avif"]}
                    alt="Key"
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <StaticImage
                    src="../images/index-carousel/02-lighting-outet.jpg"
                    width={750}
                    placeholder="blurred"
                    quality={50}
                    formats={["auto", "webp", "avif"]}
                    alt="Lighting Outlet"
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <StaticImage
                    src="../images/index-carousel/03-electrical.jpg"
                    width={750}
                    placeholder="blurred"
                    quality={50}
                    formats={["auto", "webp", "avif"]}
                    alt="Electrical Outlet"
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <StaticImage
                    src="../images/index-carousel/04-light-fixture.jpg"
                    width={750}
                    placeholder="blurred"
                    quality={50}
                    formats={["auto", "webp", "avif"]}
                    alt="Electrical Outlet"
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <StaticImage
                    src="../images/index-carousel/05-punchouts.jpg"
                    width={750}
                    placeholder="blurred"
                    quality={50}
                    formats={["auto", "webp", "avif"]}
                    alt="Punchouts"
                  />
                </Carousel.Item>
              </Carousel>
            </Col>
            </Row>
          </Container>

        <Container>
          <h2 className="text-center">As Featured In</h2>
          <Row>
            <Col xs={6} sm={3} lg={2} className="px-3">
              <StaticImage
                src="../images/awards/DOE_Logo.jpg"
                width={750}
                placeholder="blurred"
                quality={40}
                formats={["auto", "webp", "avif"]}
                alt="Department of Energy Logo"
              />
            </Col>
            <Col xs={6} sm={3} lg={2} className="px-3">
              <StaticImage
                src="../images/awards/gba_logo_resized.png"
                width={750}
                placeholder="blurred"
                quality={50}
                formats={["auto", "webp", "avif"]}
                alt="Green Building Advisor Logo"
              />
            </Col>
            <Col xs={6} sm={3} lg={2} className="px-3">
              <StaticImage
                src="../images/awards/JLC-square.png"
                width={750}
                placeholder="blurred"
                quality={50}
                formats={["auto", "webp", "avif"]}
                alt="Journal of Light Construction Logo"
              />
            </Col>
            <Col xs={6} sm={3} lg={2} className="px-3">
              <StaticImage
                src="../images/awards/MakeItRight_square.png"
                width={750}
                placeholder="blurred"
                quality={50}
                formats={["auto", "webp", "avif"]}
                alt="Make It Right Logo"
              />
            </Col>
            <Col xs={6} sm={3} lg={2} className="px-3">
              <StaticImage
                src="../images/awards/ThisOldHouse_square.png"
                width={750}
                placeholder="blurred"
                quality={50}
                formats={["auto", "webp", "avif"]}
                alt="This Old House Logo"
              />
            </Col>
            <Col xs={6} sm={3} lg={2} className="px-3">
              <StaticImage
                src="../images/awards/zero_energy_project_logo_square.jpg"
                width={750}
                placeholder="blurred"
                quality={50}
                formats={["auto", "webp", "avif"]}
                alt="Zero Energy Project Logo"
              />
            </Col>
          </Row>

          <div className="my-4">
          <h2 className="text-center mb-3">As seen on Matt Risinger's 'The Build Show'</h2>
          <p className="text-center">
            <a href="https://youtu.be/JYugiSwWoPk?si=5gwvnyTidH50CaFV" rel="noreferrer" target="_blank">
            <StaticImage
                src="../images/Know_Better_Build_Better.jpg"
                width={175}
                placeholder="blurred"
                quality={50}
                formats={["auto", "webp", "avif"]}
                alt="Matt Risinger's 'The Build Show' Logo"
                className="img-fluid"
              />
              </a>
          </p>
          </div>
          
          
          
        </Container>


        </section>
        
      </Layout>
);

export default indexPage;

export const Head = () => <Seo title="100% Air Sealing Guaranteed" />